/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    em: "em",
    h3: "h3",
    h1: "h1"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A few months ago I began diving into computer graphics programming with the\nplatform of choice being WebGL. I knew libraries like Three.js existed, but when\nlearning a new domain, I like to see what the primitives are so that\nI know what the limits of the platform are versus the limits of the tools and\nabstractions I’m using. This is a content dump of various resources I’ve\ndiscovered on my journey."), "\n", React.createElement(_components.p, null, "A good, fast-paced overview of what WebGL itself offers is Gregg Taveres’ ", React.createElement(_components.a, {
    href: "https://webglfundamentals.org"
  }, "WebGL Fundamentals"), ".\nAfter going through these I was left with a new pile of topics to learn from\nshaders and vector math, to 3D modeling and game physics."), "\n", React.createElement(_components.h2, null, "Game development"), "\n", React.createElement(_components.p, null, "One of the main points of Gregg’s tutorials is that WebGL is just a drawing API,\nto get interesting results you have to perform the math and manipulation of\ndata that represents vertices and colors, among other things. I realized that these\nproblems are what game developers have been doing for decades. So, while you may\nnot be interested in creating games, it is worth while to learn enough about game\nprogramming to know what techniques to steal."), "\n", React.createElement(_components.p, null, "One of the best resources for learning game development is Handmade Hero by\nCasey Muratori."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://hero.handmade.network/episodes"
  }, "https://hero.handmade.network/episodes"), "\nThere is a ", React.createElement(_components.em, null, "ton"), " of material here, but you can pick and choose topics of interest."), "\n", React.createElement(_components.p, null, "My search for how to get real work done led me to the book WebGL Game Development\n(linked below), this is a great place to start getting a feel for how to make things\nhappen with WebGL and a browser."), "\n", React.createElement(_components.h3, null, "Simulation loops"), "\n", React.createElement(_components.p, null, "Along the way I happened upon ", React.createElement(_components.a, {
    href: "http://gameprogrammingpatterns.com/contents.html"
  }, "Game programming patterns"), ";\nunfortunately it is steeped in object oriented\ndogma. I still recommend reading it though, as it does contain some gems,\nthe most important being the game loop chapter:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://gameprogrammingpatterns.com/game-loop.html"
  }, "http://gameprogrammingpatterns.com/game-loop.html")), "\n", React.createElement(_components.p, null, "This is a fundamental topic when doing any animation and there isn’t much out\nthere about it. Even the WebGL fundamentals chapter on animation gets it\n", React.createElement(_components.a, {
    href: "https://webglfundamentals.org/webgl/lessons/webgl-animation.html"
  }, "wrong"), " (recommends using a variable time step)."), "\n", React.createElement(_components.p, null, "The footnotes in the chapter includes references to other helpful articles on game loops:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://gafferongames.com/game-physics/fix-your-timestep"
  }, "http://gafferongames.com/game-physics/fix-your-timestep")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.koonsolo.com/news/dewitters-gameloop/"
  }, "http://www.koonsolo.com/news/dewitters-gameloop/")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://web.archive.org/web/20141116170950/http://www.richardfine.co.uk/2012/10/unity3d-monobehaviour-lifecycle/"
  }, "Unity3D behaviour lifecylce"), "\nand the updated version of that info:\n", React.createElement(_components.a, {
    href: "https://docs.unity3d.com/Manual/ExecutionOrder.html"
  }, "https://docs.unity3d.com/Manual/ExecutionOrder.html")), "\n", React.createElement(_components.p, null, "The talk “We will all be game programmers” linked below also touches on this\nimportant concept."), "\n", React.createElement(_components.p, null, "The big insight for me was that there are really two separate loops in all\nsimulations, a physics (AKA state management) loop and a rendering loop."), "\n", React.createElement(_components.p, null, "By separating how these two systems update you can control how your game performs\non a wide variety hardware with varying performance characteristics."), "\n", React.createElement(_components.h3, null, "Physics"), "\n", React.createElement(_components.p, null, "One thing that was not obvious to me is that the physics you use in games is\nnot of the analytical kind like you find in high school and college classes,\nbut is numerical instead. Meaning, you’re mostly using fairly simple algebraic\nformulas in your code instead of manipulating abstract symbols.\nThe concepts may be similar but the applications are quite different."), "\n", React.createElement(_components.p, null, "I’m only beginning to dig into this subject but here are some resources I’ve\nfound useful:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://buildnewgames.com/gamephysics/"
  }, "http://buildnewgames.com/gamephysics/"), "\n", React.createElement(_components.a, {
    href: "http://buildnewgames.com/physics-engines-comparison/"
  }, "http://buildnewgames.com/physics-engines-comparison/"), "\n", React.createElement(_components.a, {
    href: "http://gafferongames.com/game-physics"
  }, "http://gafferongames.com/game-physics")), "\n", React.createElement(_components.h3, null, "Shaders"), "\n", React.createElement(_components.p, null, "One quick point is that if you just want to render a “typical” 3D scene you\nreally only need one vertex and fragment shader that handles rendering in\n3D perspective and uses Gouraud or Phong shading, once these are set you can\nmostly use them without thinking about them."), "\n", React.createElement(_components.p, null, "Shaders is its own fascinating world which can be a dedicated area of study."), "\n", React.createElement(_components.p, null, "Some resources that focus just on fragment shaders:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://thebookofshaders.com"
  }, "http://thebookofshaders.com")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.shadertoy.com/"
  }, "https://www.shadertoy.com/")), "\n", React.createElement(_components.h1, null, "Books"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://shop.oreilly.com/product/9781849699792.do"
  }, "WebGL Game Development")), "\n", React.createElement(_components.p, null, "For showing actual examples and containing runnable code, this book stands alone.\nIt shows how to perform practical tasks like exporting models from Blender and\nrendering them via WebGL. A lot of the code samples are from parts of Three.js,\nbut instead of just using that API like a magic black box, the author guides you\nthrough how the code works, providing much needed insights into how to actually\nget stuff done and understand what is happening."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/WebGL-Programming-Guide-Interactive-Graphics/dp/0321902920/"
  }, "WebGL Programming Guide: Interactive 3D Graphics Programming with WebGL"), "\nThis book provides a low level view of the WebGL API, but isn’t very practical\nas far as getting work done goes."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Interactive-Computer-Graphics-Top-Down-Approach/dp/0133574849"
  }, "Interactive Computer Graphics: A Top-Down Approach with WebGL (7th Edition)")), "\n", React.createElement(_components.p, null, "This is a book about computer graphics intended for use as an undergraduate class\ntextbook. I found the book via a talk the authors gave at SIGGRAPH"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=tgVLb6fOVVc"
  }, "https://www.youtube.com/watch?v=tgVLb6fOVVc")), "\n", React.createElement(_components.h1, null, "Talks"), "\n", React.createElement(_components.p, null, "Some of the more insightful talks I’ve found along the way."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=avwDj3KRuLc"
  }, "We will all be game programmers")), "\n", React.createElement(_components.p, null, "A talk by Steven Wittens (", React.createElement(_components.a, {
    href: "http://acko.net"
  }, "http://acko.net"), ")"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=GNO_CYUjMK8"
  }, "Making WebGL Dance")), "\n", React.createElement(_components.p, null, "Mikola Lysenko giving an overview of ReGL:\n", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=rFjszW5L2aw"
  }, "https://www.youtube.com/watch?v=rFjszW5L2aw")), "\n", React.createElement(_components.p, null, "Here is a talk Gregg gave in 2011 which provides a nice overview of WebGL techniques.\n", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=rfQ8rKGTVlg"
  }, "https://www.youtube.com/watch?v=rfQ8rKGTVlg")), "\n", React.createElement(_components.p, null, "Edward Angel and Dave Shreiner present an overview of the benefits of using\nWebGL to teach computer graphics."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=tgVLb6fOVVc"
  }, "https://www.youtube.com/watch?v=tgVLb6fOVVc")), "\n", React.createElement(_components.h1, null, "Online classes"), "\n", React.createElement(_components.p, null, "UC Davis Computer Graphics class, covers core graphics concepts.\n", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=01YSK5gIEYQ&list=PL_w_qWAQZtAZhtzPI5pkAtcUVgmzdAP8g"
  }, "https://www.youtube.com/watch?v=01YSK5gIEYQ&list=PL_w_qWAQZtAZhtzPI5pkAtcUVgmzdAP8g")), "\n", React.createElement(_components.p, null, "UC San Diego class on edX"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://courses.edx.org/courses/course-v1:UCSDx+CSE167x+2T2016/information/"
  }, "https://courses.edx.org/courses/course-v1:UCSDx+CSE167x+2T2016/information/")), "\n", React.createElement(_components.p, null, "A code editor in WebGL"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=hM1oLr9G3-Q"
  }, "https://www.youtube.com/watch?v=hM1oLr9G3-Q")), "\n", React.createElement(_components.p, null, "Tony Parisi at HTML5 Dev Conf"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=c3_Q3T6Gzxk"
  }, "https://www.youtube.com/watch?v=c3_Q3T6Gzxk")), "\n", React.createElement(_components.h1, null, "Libraries"), "\n", React.createElement(_components.h2, null, "WeGL Utils"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/greggman/webgl-fundamentals/blob/master/webgl/resources/webgl-utils.js"
  }, "https://github.com/greggman/webgl-fundamentals/blob/master/webgl/resources/webgl-utils.js")), "\n", React.createElement(_components.p, null, "These functions can be found in a proper library:\n", React.createElement(_components.a, {
    href: "http://twgljs.org/docs/"
  }, "http://twgljs.org/docs/")), "\n", React.createElement(_components.h2, null, "MathBox"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/unconed/mathbox"
  }, "https://github.com/unconed/mathbox")), "\n", React.createElement(_components.h2, null, "regl"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://regl.party/"
  }, "http://regl.party/")), "\n", React.createElement(_components.h1, null, "Examples"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://webglsamples.org/"
  }, "http://webglsamples.org/")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
